import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'core-js';
import 'raf/polyfill';

import App from './App';

import './i18n';

import configureStore from './store/store';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app'),
);
