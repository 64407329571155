import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
  class Authentication extends Component {
    // TODO: Implement Proper redirect check here
    componentDidMount = () => {
      const { history } = this.props;
      if (!sessionStorage.getItem('token')) {
        history.push('/login');
      }
    }

    UNSAFE_componentWillUpdate = (newProps) => {
      const { history } = newProps;
      if (!sessionStorage.getItem('token')) {
        history.push('/login');
      }
    }

    render = () => <ComposedComponent {...this.props} />
  }

  Authentication.propTypes = {
    history: PropTypes.object.isRequired,
  };

  return Authentication;
}
