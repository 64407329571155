import $ from '../Common/wrapper';

export default () => {
  // NAVBAR SEARCH
  // -----------------------------------
  function NavbarSearchInput() {
    const navbarFormSelector = 'form.navbar-form';
    return {
      toggle() {
        const navbarForm = $(navbarFormSelector);

        navbarForm.toggleClass('open');

        const isOpen = navbarForm.hasClass('open');

        navbarForm.find('input')[isOpen ? 'focus' : 'blur']();
      },

      dismiss() {
        $(navbarFormSelector)
          .removeClass('open') // Close control
          .find('input[type="text"]').blur();
      },
    };
  }

  const navSearch = new NavbarSearchInput();

  // Open search input
  const $searchOpen = $('[data-search-open]');

  $searchOpen
    .on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    })
    .on('click', navSearch.toggle);

  // Close search input
  const $searchDismiss = $('[data-search-dismiss]');
  const inputSelector = '.navbar-form input[type="text"]';

  $(inputSelector)
    .on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    })
    .on('keyup', (e) => {
      if (e.keyCode === 27) { navSearch.dismiss(); }
    });

  // click anywhere closes the search
  $(document).on('click', navSearch.dismiss);
  // dismissable options
  $searchDismiss
    .on('click', (e) => {
      e.stopPropagation();
    })
    .on('click', navSearch.dismiss);
};
