import $ from '../Common/wrapper';

export default (navigator, closeSidebar) => {
  const $html = $('html');
  const $body = $('body');
  const $sidebar = $('.sidebar');

  // AUTOCOLLAPSE ITEMS
  // -----------------------------------

  const sidebarCollapse = $sidebar.find('.collapse');
  sidebarCollapse.on('show.bs.collapse', (event) => {
    event.stopPropagation();
    if ($(this).parents('.collapse').length === 0) {
      sidebarCollapse.filter('.show').collapse('hide');
    }
  });

  // SIDEBAR COLLAPSED ITEM HANDLER
  // -----------------------------------

  const isTouch = () => $html.hasClass('touch');

  const isSidebarCollapsed = () => (
    $body.hasClass('aside-collapsed')
      || $body.hasClass('aside-collapsed-text')
  );

  const isSidebarToggled = () => $body.hasClass('aside-toggled');

  const eventName = isTouch() ? 'click' : 'mouseenter';

  const useAsideHover = () => $body.hasClass('aside-hover');

  let subNav = $();

  const removeFloatingNav = () => {
    $('.sidebar-subnav.nav-floating').remove();
    $('.sidebar-backdrop').remove();
    $('.sidebar li.open').removeClass('open');
  };

  // Open the collapse sidebar submenu items when on touch devices
  // - desktop only opens on hover
  const toggleTouchItem = ($element) => {
    $element.siblings('li').removeClass('open');
    $element.toggleClass('open');
  };

  // Handles hover to open items under collapsed menu
  // -----------------------------------

  const reactClone = (domNode) => domNode;

  const isFixed = () => $body.hasClass('layout-fixed');

  const toggleMenuItem = ($listItem) => {
    removeFloatingNav();

    const ul = $listItem.find('.sidebar-nav');

    if (!ul.length) return $();
    if ($listItem.hasClass('open')) {
      toggleTouchItem($listItem);
      return $();
    }

    const $aside = $('.aside-container');
    const $asideInner = $('.aside-inner'); // for top offset calculation
    // float aside uses extra padding on aside
    const mar = parseInt($asideInner.css('padding-top'), 10)
      + parseInt($aside.css('padding-top'), 10);

    subNav = reactClone(ul.clone()).appendTo($aside);

    toggleTouchItem($listItem);

    const itemTop = $listItem.position().top + mar - $sidebar.scrollTop();
    const vwHeight = document.body.clientHeight;

    subNav.find('a').on('click.subnav', (e) => {
      e.preventDefault();
      const href = $(this).attr('href');
      navigator(href);
    });

    subNav.addClass('nav-floating').css({
      position: isFixed() ? 'fixed' : 'absolute',
      top: itemTop,
      bottom: subNav.outerHeight(true) + itemTop > vwHeight ? 0 : 'auto',
    });

    subNav.on('mouseleave', () => {
      toggleTouchItem($listItem);
      subNav.remove();
    });

    return subNav;
  };

  const sidebarAddBackdrop = () => {
    const $backdrop = $('<div/>', { class: 'sidebar-backdrop' });
    $backdrop.insertAfter('.aside-container').on('click mouseenter', () => {
      removeFloatingNav();
    });
  };

  $sidebar.find('.sidebar-nav > li').on(eventName, () => {
    if (isSidebarCollapsed() || useAsideHover()) {
      subNav.trigger('mouseleave');
      subNav = toggleMenuItem($(this));

      // Used to detect click and touch events outside the sidebar
      sidebarAddBackdrop();
    }
  });

  const sidebarAnyclickClose = $sidebar.data('sidebarAnyclickClose');

  // Allows to close
  if (typeof sidebarAnyclickClose !== 'undefined') {
    $('.wrapper').on('click.sidebar', (e) => {
      // don't check if sidebar not visible
      if (!isSidebarToggled()) return;

      const $target = $(e.target);
      if (
        !$target.parents('.aside-container').length // if not child of sidebar
        && !$target.parents('.topnavbar-wrapper').length // if not child of header
      ) {
        closeSidebar();
      }
    });
  }
}; // rendered
