const init = {
  quoteRequestList: [],
  quoteRequest: {
    RequestId: 0,
    ClaimId: '',
    CscEmailAddress: '',
    Position: '',
    Vin: '',
    OEQuote: false,
    SXQuote: false,
    AMQuote: false,
    VehicleMake: '',
    VehicleRange: '',
    VehicleModel: '',
    Branch: '',
    CreateDate: new Date(),
    Owner: sessionStorage.getItem('user'),
    Completed: false,
    Status: '',
    Quotes: [],
  },
};

export default function (state = init, action) {
  switch (action.type) {
    case 'SET_QUOTE_REQUEST_LIST':
      return {
        ...state,
        quoteRequestList: action.value,
      };
    case 'SET_QUOTE_REQUEST':
      return {
        ...state,
        quoteRequest: action.value,
      };
    case 'CLEAR_QUOTE_REQUEST':
      return {
        ...state,
        quoteRequest: init.quoteRequest,
      };
    default:
      break;
  }
  return state;
}
