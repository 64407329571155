import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import {
  withRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import _ from 'lodash';
import moment from 'moment';

/* loader component for Suspense */
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import requireAuth from './components/auth/requireAuth';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const Login = lazy(() => import('./components/Login/Login'));
const iFrameLogin = lazy(() => import('./components/Login/IframeLogin'));
const Callback = lazy(() => import('./components/auth/callback'));
const Home = lazy(() => import('./components/Home/Home'));
// const LightstoneVehicles = lazy(() => import('./components/LightstoneVehicles/LightstoneVehicles'));
const Quotes = lazy(() => import('./components/Quotes/list'));
const QuoteRequest = lazy(() => import('./components/Quotes/quoteRequest'));
const SubmitQuote = lazy(() => import('./components/SubmitQuote/submit'));
const CarMaintenance = lazy(() => import('./components/CarMaintenance/CarMaintenance'));
const UpdateCar = lazy(() => import('./components/UpdateCar/UpdateCar'));
const GlassMaintenance = lazy(() => import('./components/GlassMaintenance/GlassMaintenance'));
const UpdateGlass = lazy(() => import('./components/UpdateGlass/UpdateGlass'));
const SupplierInfo = lazy(() => import('./components/SupplierAdmin/SupplierInformation'));
const UpdateSupplier = lazy(() => import('./components/SupplierAdmin/UpdateSupplier'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  'login',
  'callback',
  'submit-quote',
];

const Routes = ({ location }) => {
  setTimeout(function authCheck() {
    if (!_.isEmpty(sessionStorage.getItem('expires_at'))) {
      if (moment(new Date()).isSameOrAfter(moment(sessionStorage.getItem('expires_at')))) {
        sessionStorage.clear();
        window.open('/', '_parent');
      }
    }
    setTimeout(authCheck, 5000);
  }, 5000);

  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = 'rag-fadeIn';

  // alert(location.pathname.split('/')[1]);
  if (listofPages.indexOf(location.pathname.split('/')[1]) > -1) {
    return (
    // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/callback" component={waitFor(Callback)} />
            <Route path="/submit-quote/:quoteId" component={waitFor(SubmitQuote)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  }

  if (location.pathname.split('/')[1] === 'iframe') {
    return (
      <TransitionGroup>
        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
          <div>
            <Suspense fallback={<PageLoader />}>
              <Switch location={location}>
                <Route path="/iframe/login" component={waitFor(iFrameLogin)} />
                <Route path="/iframe" component={requireAuth(waitFor(Home))} />
              </Switch>
            </Suspense>
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }

  return (
  // Layout component wrapper
  // Use <BaseHorizontal> to change layout
    <Base>
      <TransitionGroup>
        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
          <div>
            <Suspense fallback={<PageLoader />}>
              <Switch location={location}>
                <Route path="/vehicle-search" component={requireAuth(waitFor(Home))} />
                {/* <Route path="/lightstone-vehicles" component={requireAuth(waitFor(LightstoneVehicles))} /> */}
                <Route path="/quotes" component={requireAuth(waitFor(Quotes))} />
                <Route path="/quote-request/:id" component={requireAuth(waitFor(QuoteRequest))} />
                <Route path="/quote-request" component={requireAuth(waitFor(QuoteRequest))} />
                <Route path="/car-maintenance" component={requireAuth(CarMaintenance)} />
                <Route path="/glass-maintenance" component={requireAuth(GlassMaintenance)} />
                <Route path="/supplier-info" component={requireAuth(SupplierInfo)} />
                <Route path="/update-car/:id" component={requireAuth(UpdateCar)} />
                <Route path="/update-glass/:id" component={requireAuth(UpdateGlass)} />
                <Route path="/update-supplier/:id" component={requireAuth(UpdateSupplier)} />
                <Redirect exact path="/" to="/vehicle-search" />
              </Switch>
            </Suspense>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Base>
  );
};

Routes.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Routes);
