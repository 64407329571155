/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import SidebarRun from './Sidebar.run';

import Menu from '../../Menu';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>{item.heading}</span>
  </li>
);

SidebarItemHeader.propTypes = {
  item: PropTypes.object.isRequired,
};

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  <li className={isActive ? 'active' : ''}>
    <Link to={`/${item.path}`} title={item.name}>
      {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
      {item.icon && <em className={item.icon} />}
      <span>{item.name}</span>
    </Link>
  </li>
);

SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
};

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({
  item, isActive, handler, children, isOpen,
}) => (
  <li className={isActive ? 'active' : ''}>
    <div
      tabIndex="-1"
      role="button"
      className="nav-item"
      onClick={handler}
      onKeyPress={handler}
    >
      {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
      {item.icon && <em className={item.icon} />}
      <span>{item.name}</span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        { children }
      </ul>
    </Collapse>
  </li>
);

SidebarSubItem.propTypes = {
  item: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  handler: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
};

SidebarSubItem.defaultProps = {
  isOpen: true,
};

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">{item.name}</li>
);

SidebarSubHeader.propTypes = {
  item: PropTypes.object.isRequired,
};

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: {},
    };
  }

  componentDidMount = () => {
    // pass navigator to access router api
    SidebarRun(this.navigator, this.closeSidebar);
    // prepare the flags to handle menu collapsed states
    this.buildCollapseList();

    // Listen for routes changes in order to hide the sidebar on mobile
    this.props.history.listen(this.closeSidebar);
  }

    closeSidebar = () => {
      this.props.actions.toggleSetting('asideToggled');
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
      const collapse = {};
      Menu
        .filter(({ heading }) => !heading)
        .forEach(({ name, path, submenu }) => {
          collapse[name] = this.routeActive(submenu ? submenu.map(({ path1 }) => path1) : path);
        });
      this.setState({ collapse });
    }

    navigator = (route) => {
      this.props.history.push(route);
    }

    routeActive = (paths) => {
      paths = Array.isArray(paths) ? paths : [paths];
      return paths.some((p) => this.props.location.pathname.indexOf(p) > -1);
    }

    toggleItemCollapse = (stateName) => {
      const collapse = Object.keys(this.state.collapse);
      collapse.forEach((key) => {
        if (this.state.collapse[key] === true && key !== stateName) {
          this.setState({
            collapse: {
              [key]: false,
            },
          });
        }
      });
      this.setState({
        collapse: {
          [stateName]: !this.state.collapse[stateName],
        },
      });
    }

    getSubRoutes = (item) => item.submenu.map(({ path }) => path)

    /** map menu config to string to determine which element to render */
    itemType = (item) => {
      if (item.heading) return 'heading';
      if (!item.submenu) return 'menu';
      if (item.submenu) return 'submenu';
    }

    render() {
      return (
        <aside className="aside-container">
          { /* START Sidebar (left) */ }
          <div className="aside-inner">
            <nav data-sidebar-anyclick-close="" className="sidebar">
              { /* START sidebar nav */ }
              <ul className="sidebar-nav">
                {Menu.map((item, i) => {
                  // heading
                  if (this.itemType(item) === 'heading') {
                    return (
                      <SidebarItemHeader item={item} key={i} />
                    );
                  }

                  if (this.itemType(item) === 'menu') {
                    return (
                      <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                    );
                  }
                  if (this.itemType(item) === 'submenu') {
                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={this.state.collapse[item.name]}
                        handler={this.toggleItemCollapse.bind(this, item.name)}
                        isActive={this.routeActive(this.getSubRoutes(item))}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subitem, o) => (
                          <SidebarItem
                            key={o}
                            item={subitem}
                            isActive={this.routeActive(subitem.path)}
                          />
                        ))}
                      </SidebarSubItem>,
                    ];
                  }

                  return null; // unrecognized item
                })}
              </ul>
            </nav>
          </div>
        </aside>
      );
    }
}

Sidebar.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('translations')(withRouter(Sidebar)));
