import React from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

import '@progress/kendo-theme-default/dist/all.css';

// Vendor dependencies
import './Vendor';
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';

const App = () => {
  const basename = '/';

  return (
    <BrowserRouter basename={basename}>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
