const init = {
  cards: [],
};

export default function (state = init, action) {
  switch (action.type) {
    case 'SET_AUTOVALID_CARDS':
      return {
        cards: action.value,
      };
    default:
      break;
  }
  return state;
}
