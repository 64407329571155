import { combineReducers } from 'redux';

import authReducer from './auth.reducer';
import autovalidReducer from './autovalid.reducer';
import quoteEngineReducer from './quoteEngine.reducer';
import settingsReducer from './settings.reducer';
import themesReducer from './themes.reducers';

export default combineReducers({
  auth: authReducer,
  autovalid: autovalidReducer,
  quoteEngine: quoteEngineReducer,
  settings: settingsReducer,
  theme: themesReducer,
});
