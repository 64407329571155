import { SET_AUTH_USER } from '../../actions/auth/authTypes';

const init = {
  authenticated: false,
  user: null,
};

export default function (state = init, action) {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        authenticated: action.value.authenticated,
        user: action.value.user,
      };
    default:
      break;
  }
  return state;
}
