import React from 'react';
import PropTypes from 'prop-types';

const BasePage = (props) => (
  <div className="wrapper">
    { props.children }
  </div>
);

BasePage.propTypes = {
  children: PropTypes.any.isRequired,
};

export default BasePage;
