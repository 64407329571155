const Menu = [
  {
    heading: 'Main Navigation',
    translate: 'sidebar.heading.HEADER',
  },
  {
    name: 'Vehicle Search',
    path: 'vehicle-search',
    icon: 'fas fa-car',
    translate: 'sidebar.nav.SINGLEVIEW',
  },
  {
    name: 'Quotes',
    path: 'quotes',
    icon: 'fas fa-quote-right',
    translate: 'sidebar.nav.SINGLEVIEW',
  },
  {
    name: 'Administration',
    icon: 'fas fa-toolbox',
    translate: 'sidebar.nav.SINGLEVIEW',
    submenu: [
      {
        name: 'Vehicle Maintenance',
        path: 'car-maintenance',
      },
      {
        name: 'Glass Maintenance',
        path: 'glass-maintenance',
      },
      {
        name: 'Supplier Information',
        path: 'supplier-info',
      },
      {
        name: 'Branch Information',
        path: 'branch-info',
      },

    ],
  },
];

export default Menu;
